import { styled } from '@mui/material';
import GRAPHICS from '../../../../../../../assets/images/assessment';

export const Wrapper = styled('div')(({ theme }) => ({
  backgroundColor: 'white',
  display: 'flex',
  flexDirection: 'column',
  gap: '30px',
  padding: '50px 25px',
  borderRadius: '10px',
  boxShadow: '4px 4px 17px 0px #0000001C',
  [theme.breakpoints.down('sm')]: {
    padding: '50px 0px',
  },
}));

export const ImgWrapper = styled('div')(() => ({
  width: '40%',
}));

export const ContentWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '30px',
  flexWrap: 'wrap',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

export const DetailWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '30px',
  width: '50%',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

export const DetailBox = styled('div')(({ theme }) => ({
  color: '#16263c',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: '1px solid #E8E8E8',
  fontSize: '16px',
  fontFamily: 'Inter',
  paddingBottom: '6px',
  fontWeight: 400,
  [theme.breakpoints.down('sm')]: {
    padding: '0px 10px',
  },
}));

export const DetailContent = styled('span')(() => ({
  color: '#624BA2',
  fontWeight: 600,
  fontFamily: 'Inter',
}));

export const Image = styled('img')(() => ({
  width: '100%',
  height: '100%',
  objectFit: 'contain',
}));

export const Icon = styled('img')(({ opacity }) => ({
  opacity: opacity ? opacity : 1,
}));

export const Heading = styled('span')(() => ({
  fontSize: 24,
  fontWeight: 600,
  color: '#11263C',
  fontFamily: 'Inter',
}));

export const HeadingTwo = styled('span')(({ theme }) => ({
  fontSize: '24px',
  fontFamily: 'Inter',
  fontWeight: 600,
  color: '#11263C',
  padding: '0px 0px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '16px',
    padding: '0px 10px',
  },
}));

export const SubHeading = styled('span')(() => ({
  fontSize: 14,
  fontWeight: 400,
  color: '#E015A2',
  fontFamily: 'Inter',
  gap: '10px',
  display: 'flex',
  marginTop: '5px',
}));

export const PinkText = styled('span')(({ theme, fontSize, fontWeight }) => ({
  fontSize: fontSize ? fontSize : 18,
  fontWeight: fontWeight ? fontWeight : 600,
  color: '#E015A2',
  fontFamily: 'Inter',
  [theme.breakpoints.down('sm')]: {
    fontSize: 10,
  },
}));

export const StartButtonWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'end',
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'center',
  },
}));

export const StartButton = styled('button')(() => ({
  color: 'white',
  backgroundColor: '#624BA2',
  padding: '11px 21px',
  width: '190px',
  height: '55px',
  borderRadius: '8px',
  outline: 'none',
  border: 'none',
  cursor: 'pointer',
  fontFamily: 'Inter',
  fontWeight: 600,
  fontSize: 14,
}));

export const FilterWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'end',
  gap: '10px',
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'center',
  },
}));

export const FilterDiv = styled('div')(() => ({}));

export const Range = styled('span')(() => ({
  fontWeight: 400,
  fontSize: 14,
  marginRight: 10,
  color: '#ADB4C5',
  fontFamily: 'Inter',
}));

export const BoldText = styled('span')(() => ({
  fontWeight: 700,
  padding: '0px',
}));

// Question Paper CSS

export const QuestionModal = styled('div')(() => ({
  background: '#f8f8fc',
  width: '100%',
  padding: '120px 0px 50px',
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 1500,
  backgroundImage: `url(${GRAPHICS.QuestionSVG})`,
  display: 'flex',
  justifyContent: 'center',
  minHeight: '100vh',
}));

export const QuestionContainer = styled('div')(() => ({
  backgroundColor: 'white',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '10px',
  width: '80%',
  boxShadow: '4px 4px 17px 0px #0000001C',
}));

export const Header = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: '1px solid rgba(229, 99, 189, 0.2)',
  padding: '25px',
}));

export const Content = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '35px 25px',
  gap: '30px',
}));

export const LoaderWrapper = styled('div')(() => ({
  height: '94vh',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const ChapterTitle = styled('div')(() => ({
  fontWeight: 700,
  fontFamily: 'Inter',
  fontSize: 18,
  fontStyle: 'italic',
  color: '#11263c',
  lineHeight: 'normal',
}));

export const QuestionText = styled('span')(() => ({
  fontWeight: 600,
  fontFamily: 'Inter',
  fontSize: 18,
}));

export const AdjacentText = styled('span')(({ theme }) => ({
  fontWeight: 400,
  fontFamily: 'Satoshi',
  fontSize: 18,
  userSelect: 'none',
  padding: 0,
  color: '#251038',
  [theme.breakpoints.down('sm')]: {
    fontSize: 12,
  },
}));
export const ScoreContainerWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

export const SubmitBtnWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  marginTop: '0px',
  [theme.breakpoints.down('md')]: {
    justifyContent: 'center',
    marginTop: '20px',
  },
}));

export const ScoreContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: '20px',
  alignItems: 'center',
  marginLeft: '150px',
  [theme.breakpoints.down('md')]: {
    marginLeft: '0px',
  },
}));

export const QuestionImgWrapper = styled('div')(() => ({
  minHeightheight: '45vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const QuestionImage = styled('img')(() => ({
  width: '100%',
  height: '100%',
  maxHeight: '60vh',
  objectFit: 'conver',
  borderRadius: 15,
}));

export const ConfirmModalWrapper = styled('div')(() => ({
  height: '100vh',
  width: '100%',
  position: 'fixed',
  top: 0,
  zIndex: 2000,
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const ConformationModal = styled('div')(() => ({
  backgroundColor: 'white',
  width: '450px',
  height: '',
  borderRadius: '15px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '20px',
  paddingTop: '20px',
}));

export const CrossButtonWrapper = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'end',
  width: '100%',
  paddingRight: '30px',
}));

export const RadioBtnCustomLabel = styled('span')(({ theme, selected }) => ({
  fontWeight: 400,
  fontSize: '20px',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  textAlign: 'center',
  color: selected ? '#BF3895' : '#251038',
}));

export const TextWrapper = styled('div')(() => ({
  fontWeight: 600,
  fontSize: '16px',
  letterSpacing: '1px',
  fontFamily: 'AvenirNext',
  textAlign: 'center',
}));

export const ConformationBtnWrapper = styled('div')(() => ({
  display: 'flex',
  width: '100%',
  borderTop: '1px solid #EBEBEB',
  fontWeight: 600,
  fontSize: '14px',
  letterSpacing: '1px',
}));

export const ReviewButton = styled('button')(() => ({
  width: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '17px 0',
  cursor: 'pointer',
  border: 'none',
  outline: 'none',
  fontSize: '14px',
  fontFamily: 'AvenirNext',
  fontWeight: 600,
}));

export const ProceedButton = styled('button')(() => ({
  backgroundColor: '#E015A2',
  width: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '17px 0',
  borderBottomRightRadius: '15px',
  color: 'white',
  cursor: 'pointer',
  border: 'none',
  outline: 'none',
  fontSize: '14px',
  fontFamily: 'AvenirNext',
  fontWeight: 600,
}));

// Progress Bar CSS

export const ProgressBarContainer = styled('div')(() => ({
  width: '100%',
  backgroundColor: '#AAA5B1',
  height: 6,
  borderRadius: 10,
  marginTop: 25,
  marginBottom: 25,
}));

export const AciveBar = styled('div')(({ percentage }) => ({
  width: `${percentage}%`,
  backgroundColor: '#624BA2',
  height: 6,
  borderRadius: 10,
  display: 'flex',
  justifyContent: 'end',
  alignItems: 'center',
  boxShadow: '2px 5px 12px #767475',
  minWidth: '56px',
}));

export const CircleShadow = styled('div')(() => ({
  backgroundColor: 'white',
  width: 56,
  height: 56,
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  boxShadow: '2px 5px 12px #767475',
}));

export const Value = styled('span')(() => ({
  color: 'white',
  fontSize: 12,
  fontWeight: 600,
  backgroundColor: '#624BA2',
  width: 44,
  height: 44,
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontFamily: 'Inter',
}));

// Review Screen CSS

export const ReviewSubmitSection = styled('div')(() => ({
  border: '1px solid #F0F0FB',
  borderRadius: '15px',
  marginBottom: '30px',
  backgroundColor: '#E0DBEC',
}));
export const ClickAwayWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const SubmitSection = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: '40px',
  height: '170px',
  gap: '20px',
  backgroundColor: '#11263C',
  borderRadius: '15px',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    padding: '40px 10px',
    justifyContent: 'space-between',
    height: '200px',
  },
}));

export const BtnSection = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '40%',
  gap: '5px',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
}));

export const ReviewHeading = styled('span')(({ theme }) => ({
  fontFamily: 'Inter',
  fontWeight: 600,
  fontStyle: 'normal',
  fontSize: '18px',
  color: 'white',
  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
  },
}));

export const ReviewText = styled('span')(({ theme }) => ({
  fontFamily: 'Inter',
  fontWeight: 400,
  fontSize: '15px',
  color: 'white',
  [theme.breakpoints.down('sm')]: {
    fontSize: '12px',
  },
}));

export const ReviewSubmitButton = styled('button')(({ theme }) => ({
  marginTop: '5px',
  color: 'white',
  backgroundColor: '#E015A2',
  padding: '15px 30px',
  borderRadius: '10px',
  outline: 'none',
  border: 'none',
  cursor: 'pointer',
  fontFamily: 'Inter',
  fontWeight: 600,
  fontSize: 14,
  boxShadow: '0 5px 34px #624BA247',
  [theme.breakpoints.down('sm')]: {
    fontSize: '12px',
    padding: '10px 15px',
  },
}));

export const HintSection = styled('div')(({ theme }) => ({
  fontFamily: 'Inter',
  color: '#624BA2',
  padding: '15px 40px',
  borderBottomRightRadius: '15px',
  borderBottomLeftRadius: '15px',
  fontWeight: 600,
  fontSize: '14px',
  backgroundColor: '#E0DBEC',
  [theme.breakpoints.down('sm')]: {
    fontSize: '12px',
  },
}));

export const ReviewCardWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
}));
export const StyledStatusIndicator = styled('div')(({ theme, isCorrect }) => ({
  background:
    isCorrect === true
      ? '#5FA4A8'
      : isCorrect === false
      ? '#E015A2'
      : '#ADB4C5',
  width: '100px',
  height: '29px',
  color: 'white',
  textAlign: 'center',
  borderRadius: '5px 0 0 5px',
  fontFamily: 'Inter',
  fontSize: '12px',
  fontWeight: 600,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));
export const RQuestionCard = styled('div')(() => ({
  border: '1px solid #F0F0FB',
  borderRadius: '15px',
  display: 'flex',
  boxShadow: '-15px 3px 12px #F0F0FB99',
}));

export const CardNumber = styled('div')(({ theme, isResult }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '10%',
  backgroundColor: `rgba(224, 21, 162, ${isResult ? 0.1 : 0.2})`,
  color: '#E015A2',
  borderTopLeftRadius: '15px',
  borderBottomLeftRadius: '15px',
  fontSize: '16px',
  fontWeight: 500,
  [theme.breakpoints.down('sm')]: {
    fontSize: 12,
  },
}));

export const CardContentWrapper = styled('div')(({ theme }) => ({
  padding: '25px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '20px',
  backgroundColor: 'white',
  width: '100%',
  borderTopRightRadius: '15px',
  borderBottomRightRadius: '15px',
  position: 'relative',
  [theme.breakpoints.down('sm')]: {
    padding: '5px',
  },
}));

export const CardImgWrapper = styled('div')(({ theme }) => ({
  minHeight: '45vh',
  width: '45vw',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    minHeight: '30vh',
  },
}));

export const CardImage = styled('img')(() => ({
  height: '100%',
  width: '100%',
  borderRadius: 15,
}));

// Welcome Screen CSS

export const WelcomeScreenWrapper = styled('div')(({ theme }) => ({
  borderRadius: '10px',
  backgroundColor: 'white',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  gap: '20px',
  minHeight: '100%',
  boxShadow: '4px 4px 17px 0px rgba(0, 0, 0, 0.11)',
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

export const BlurWrapper = styled('h1')(() => ({
  filter: 'blur(8px)',
  width: '100%',
  display: 'flex',
  minHeight: '100%',
  maxHeight: '100%',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '20px',
  zIndex: 1,
  background: '#00000066',
  margin: 0,
}));

export const WelcomeHeading = styled('h1')(() => ({
  color: '#11263C',
  fontSize: '24px',
  fontWeight: 600,
  fontFamily: 'Inter',
}));

export const QuickGuideWrapper = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const QuickGuide = styled('span')(() => ({
  fontFamily: 'Inter',
  fontSize: '16px',
  fontWeight: 600,
  color: '#11263c',
}));

export const GuideContent = styled('span')(() => ({
  fontSize: '16px',
  fontWeight: 500,
  color: '#624BA2',
  fontFamily: 'Inter',
  textAlign: 'start',
  maxWidth: '560px',
}));

// Result Screen CSS

export const AssessmentCompletionWrapper = styled('div')(() => ({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  background: 'rgba(0, 0, 0, 0.4)',
  zIndex: 5000,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'rgba(217, 217, 217, 0.30)',
  backdropFilter: 'blur(9.5px)',
  zIndex: '99999',
}));

export const AssessmentCompletionModal = styled('div')(() => ({
  background: 'white',
  borderRadius: '15px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '20px',
  width: '430px',
  height: '330px',
  position: 'relative',
}));

export const AssessmentLoaderWrapper = styled('div')(() => ({
  width: '90%',
  position: 'absolute',
  top: 0,
}));

export const AssessmentModalHeading = styled('span')(() => ({
  fontSize: '24px',
  fontFamily: 'Inter',
  fontWeight: 600,
}));

export const ResultScoresWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: '20px',
  marginBottom: '30px',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

export const ResultCorrectScores = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  background: '#EAF2F2',
  borderRadius: '15px',
  padding: '20px',
  width: '50%',
  cursor: 'pointer',
  [theme.breakpoints.down('sm')]: {
    width: '90%',
  },
}));

export const ResultIncorrectScores = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  background: '#FCEFF8',
  borderRadius: '15px',
  padding: '20px',
  width: '50%',
  cursor: 'pointer',
  [theme.breakpoints.down('sm')]: {
    width: '90%',
  },
}));

export const ResultScoresContentWrapper = styled('span')(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const ResultScoresHeading = styled('span')(() => ({
  fontSize: '16px',
  fontWeight: '600',
  fontFamily: 'Inter',
  color: '#251038',
}));
export const PercentageSpan = styled('span')(({ customLeft }) => ({
  fontSize: '13px',
  fontFamily: 'Inter',
  color: '#fff',
  fontWeight: '400',
  textAlign: 'center',
  position: 'absolute',
  zIndex: 9,
  top: '10px',
  left: customLeft ? '4px' : '7px',
}));

export const ResultScoresContent = styled('span')(() => ({
  fontSize: '14px',
  fontFamily: 'Inter',
  color: '#251038',
  fontWeight: '400',
}));

export const ResultProgrssWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  gap: '10px',
  alignItems: 'center',
}));

export const ResultBackWrapper = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: '30px',
}));

// Assement Completion Modal CSS

export const AssessmentCompleteModal = styled('div')(() => ({
  background: 'white',
  borderRadius: '15px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '20px',
  width: '400px',
  maxHeightheight: '330px',
  position: 'relative',
  padding: '20px',
}));

export const AssesementModalClose = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'end',
  width: '100%',
  cursor: 'pointer',
}));

export const AssessmentModalContent = styled('div')(() => ({
  fontSize: '16px',
  fontFamily: 'Inter',
  textAlign: 'center',
  color: '#624BA2',
}));

export const AssessmentModalTip = styled('div')(() => ({
  fontSize: '16px',
  fontFamily: 'Inter',
  textAlign: 'center',
}));

export const AssessmentAnalyticsWrapper = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'end',
  alignItems: 'center',
  gap: '10px',
}));

export const AssessmentAnalyticsHint = styled('div')(() => ({
  fontSize: '12px',
  fontFamily: 'Inter',
  color: '#624BA2',
}));

export const PagniationInfo = styled('p')(() => ({
  fontSize: '14px',
  fontFamily: 'Inter',
  color: ' #939393',
  fontWeight: 400,
}));
