import React from 'react';

const UserTimeSvg = () => {
  return (
    <svg
      width="30"
      height="25"
      viewBox="0 0 25 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.375 8.75C16.2656 8.75 13.75 11.2656 13.75 14.375C13.75 17.4844 16.2656 20 19.375 20C22.4844 20 25 17.4844 25 14.375C25 11.2656 22.4844 8.75 19.375 8.75ZM21.875 14.6211C21.875 14.8281 21.7031 15 21.4961 15H19.1289C18.9219 15 18.75 14.8281 18.75 14.6211V11.6289C18.75 11.4219 18.9219 11.25 19.1289 11.25H19.6211C19.8281 11.25 20 11.4219 20 11.6289V13.75H21.4961C21.7031 13.75 21.875 13.9219 21.875 14.1289V14.6211ZM12.5 14.375C12.5 13.2891 12.7617 12.2617 13.2109 11.3477C12.8984 11.2891 12.5781 11.25 12.25 11.25H11.5977C10.7305 11.6484 9.76562 11.875 8.75 11.875C7.73438 11.875 6.77344 11.6484 5.90234 11.25H5.25C2.35156 11.25 0 13.6016 0 16.5V18.125C0 19.1602 0.839844 20 1.875 20H15.4336C13.6641 18.7539 12.5 16.6992 12.5 14.375ZM8.75 10C11.5117 10 13.75 7.76172 13.75 5C13.75 2.23828 11.5117 0 8.75 0C5.98828 0 3.75 2.23828 3.75 5C3.75 7.76172 5.98828 10 8.75 10Z"
        fill="#E015A2"
      />
    </svg>
  );
};

export default UserTimeSvg;
