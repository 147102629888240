import React, { useEffect, useState } from 'react';
import { MainContainer } from './courseInfo.style';
import {
  MobileCourseImageC,
  MobileCourseWelcomeHeading,
  MobileCourseWelcomedetails,
} from '../Containers/Subject/Tabs/LearningResources/Tabs/Notes/octiNotes.style';
import api from '../../Services';
import { formatText } from '../UI/List/Utils';
import { useSelector } from 'react-redux';
import physics from '../../assets/images/courses/physics.png';

function CourseInfo({ tab, welcomeDetails }) {
  const { title, img } = useSelector((state) => state.course);
  return (
    <MainContainer>
      <MobileCourseImageC
        src={title?.includes('physics') ? physics : img}
        height={'200px'}
      />
      <MobileCourseWelcomeHeading sx={{ marginTop: '20px' }}>
        Welcome to the {title && formatText(title)} {tab} section!
      </MobileCourseWelcomeHeading>
      <MobileCourseWelcomedetails>
        {welcomeDetails
          ? welcomeDetails
          : `Please select Unit or Chapter to expand ${tab} section  from left tree `}
      </MobileCourseWelcomedetails>
    </MainContainer>
  );
}

export default CourseInfo;
// {
/* <Container>
  <MobileCourseImageC src={imageUrl} />
  <MobileCourseWelcomeWrapper>
    <MobileCourseWelcomeHeading>
      Welcome to the {courseName && formatText(courseName)} {tab} section
    </MobileCourseWelcomeHeading>
    <MobileCourseWelcomedetails>
      {welcomeDetails
        ? welcomeDetails
        : `Please select a unit, chapter or snack to view ${tab}`}
    </MobileCourseWelcomedetails>
  </MobileCourseWelcomeWrapper>
  <MobileCourseWelcomeArrowDown>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="46"
      viewBox="0 0 8 46"
      fill="none"
    >
      <path
        d="M3.64645 45.5881C3.84171 45.7834 4.15829 45.7834 4.35355 45.5881L7.53553 42.4061C7.7308 42.2109 7.7308 41.8943 7.53553 41.699C7.34027 41.5038 7.02369 41.5038 6.82843 41.699L4 44.5275L1.17157 41.699C0.976311 41.5038 0.659728 41.5038 0.464466 41.699C0.269204 41.8943 0.269204 42.2109 0.464466 42.4061L3.64645 45.5881ZM3.5 0.234558V45.2346H4.5V0.234558H3.5Z"
        fill="#624BA2"
      />
    </svg>
  </MobileCourseWelcomeArrowDown>
</Container>; */
// }
