import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import BreadCrumb from '../../../../BreadCrumb';
import api from '../../../../../../../Services';
import React, { useEffect, useState } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import Filter from '../Assessment/components/Filter';
import GridComp from '../../../../../../UI/Grid/Grid';
import CardComp from '../../../../../../UI/Card/Card';
import Loader from '../../../../../../UI/Loader/Loader';
import LeftSide from '../../../../LeftSideBar/LeftSide';
import AnalyticsScreen from './Components/AnalyticsScreen';
import capitalize from '../../../../../../../helpers/capitalize';
import GRAPHICS from '../../../../../../../assets/images/assessment';
import useUserResponseAwait from '../../../../hooks/useUserResponseAwait';
import MobileBottomBar from '../../../../../../mobileBottomBar/MobileBottomBar';
import MobileCourseIndicator from '../../../../../../mobileBottomBar/MobileCourseIndicator';
import {
  AnalyticsHeaderWrapper,
  AnalyticsNewUIWrapper,
  AnalyticsContent,
  AnlayticsResetBtn,
  ConfirmationText,
  DeleteModalBtnWrapper,
  DeleteModalWrapper,
  DeleteModel,
  DeleteNoButton,
  DeleteYesButton,
  DeletemodalClose,
  PurpleText,
  ResetWrapper,
  WelcomeHeading,
  WelcomeImage,
  UnorderList,
  ResetBtnWrapper,
} from './Analytics.style';

const Analytics = () => {
  const params = useParams();
  const { id: courseId } = params;

  const [newSelection, setNewSelection] = useState(null);
  const [hierarchyData, setHeirarchyData] = useState(null);
  const [showAnalytics, setShowAnalytics] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [names, setName] = useState([]);
  const [activeLevel, setActiveLevel] = useState(null);
  const [analyticsData, setAnalyticsData] = useState(null);
  const [analyticsCorrectAns, setAnalyticsCorrectAns] = useState(null);
  const [chapter, setChapter] = useState('');
  const [loading, setLoading] = useState(false);
  const [openMobileBar, setOpenMobileBar] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [activeItem, setActiveItem] = useState('');
  const [structuredData, setStructuredData] = useState([]);
  const [incorrectQuestions, setIncorrectQuestions] = useState([]);
  const [showQuestions, setShowQuestions] = useState(false);
  const [isCorrect, setIsCorrect] = useState(null);

  const { toggleLoading } = useUserResponseAwait();

  const initialLImit = new Date().getFullYear() - 5;

  const handleExpansion = (item, structure) => {
    switch (item.type) {
      case 'unit':
        const updatedData = structure.map((unit) => {
          if (unit.id === item.id) {
            return {
              ...unit,
              isExpanded: !unit.isExpanded,
              nested: unit.childrenElements,
            };
          }
          return { ...unit, isExpanded: false, nested: unit.childrenElements };
        });
        return updatedData;
        break;

      case 'chapter':
        if (item.unitId) {
          let updatedData = structure.map((unit) => {
            if (unit.id === item.unitId) {
              const modifiedChildren = unit.childrenElements.map((element) => {
                if (element.id === item.id) {
                  return {
                    ...element,
                    isExpanded: !element.isExpanded,
                    nested: element.childrenElements,
                  };
                }
                return {
                  ...element,
                  isExpanded: false,
                  nested: element.childrenElements,
                };
              });
              return {
                ...unit,
                childrenElements: modifiedChildren,
                nested: modifiedChildren,
              };
            }

            return unit;
          });
          return updatedData;
        } else {
          let updatedData = structure.map((chapter) => {
            if (chapter.id === item.id) {
              return {
                ...chapter,
                isExpanded: !chapter.isExpanded,
                nested: chapter.childrenElements,
              };
            }
            return {
              ...chapter,
              isExpanded: false,
              nested: chapter.childrenElements,
            };
          });
          return updatedData;
        }
        break;

      case 'snack':
        const updatedDataSnack = structure.map((unit) => {
          if (unit.id === item.unitId) {
            let modifiedUnitsChildren = unit.childrenElements.map((element) => {
              if (element.id === item.chapterId) {
                let modifiedSnacksChildren = element.childrenElements.map(
                  (snackElements) => {
                    if (snackElements.id === item.id) {
                      return {
                        ...snackElements,
                        isExpanded: !snackElements.isExpanded,
                        nested: snackElements.childrenElements,
                      };
                    }
                    return snackElements;
                  }
                );
                return {
                  ...element,
                  childrenElements: modifiedSnacksChildren,
                  nested: modifiedSnacksChildren,
                };
              }
              return element;
            });
            return {
              ...unit,
              childrenElements: modifiedUnitsChildren,
              nested: modifiedUnitsChildren,
            };
          }

          return unit;
        });
        return updatedDataSnack;
      default:
        console.log('Unknown item type');
    }
  };

  const [filter, setFiler] = useState({
    from: initialLImit?.toString(),
    to: new Date(),
  });

  const [updatedFilter, setUpdatedFilter] = useState({
    from: 2019,
    to: 2024,
  });

  const handleOpenMobileBar = () => {
    setOpenMobileBar(!openMobileBar);
  };

  const course = useSelector((state) => state.course);

  const _1000px = useMediaQuery('(min-width: 1000px)');
  const _750px = useMediaQuery('(max-width: 750px)');

  const left = _1000px ? 3 : _750px ? 12 : 4;
  const right = _1000px ? 9 : _750px ? 12 : 8;

  function handleSelection(e) {
    const analyticscard = handleExpansion(e, structuredData);
    const analyticscardGlobal = {
      data: analyticscard,
      tab: 'analyticscards',
      selection: e,
    };
    localStorage.setItem(
      'analyticscardsGlobalState',
      JSON.stringify(analyticscardGlobal)
    );
    handleSelectedData(e);
  }

  function handleSelectedData(e) {
    if (e.type === 'unit') {
      setSelectedData({
        ...e,
        unitname: e.name,
        type: 'unit',
        active: 'unit',
      });
    } else if (e.type === 'chapter') {
      setSelectedData((prevData) => ({
        ...prevData,
        ...e,
        chaptername: e.name,
        type: 'chapter',
        active: 'chapter',
      }));
    } else {
      setSelectedData((prevData) => ({
        ...prevData,
        ...e,
        snackname: e.name,
        type: 'snack',
        active: 'snack',
      }));
    }
  }

  const listItemClickHandler = (selectedListItem, isStorage = false) => {
    if (isStorage) {
      if (selectedListItem.isExpanded) {
        setShowAnalytics(true);
        setActiveLevel(selectedListItem?.type);

        if (selectedListItem?.type !== 'unit') {
          const getName =
            selectedListItem?.type === 'chapter'
              ? selectedListItem?.nested?.map((item) => item?.name)
              : selectedListItem?.label;
          setName(getName);
          getUserAnalyticsData(selectedListItem?.id, selectedListItem?.type);
        }

        if (selectedListItem?.type === 'chapter') {
          setChapter(selectedListItem?.label);
        }
      }
      setActiveLevel(selectedListItem?.type);
      setActiveItem(selectedListItem.label);
      setNewSelection(selectedListItem);
      handleSelectedData(selectedListItem);

      if (selectedListItem.layer === 2 && !selectedListItem.isExpanded) {
        setShowAnalytics(false);
      }
    } else {
      if (selectedListItem.isExpanded) {
        setShowAnalytics(true);
        setActiveLevel(selectedListItem?.type);

        if (selectedListItem?.type !== 'unit') {
          const getName =
            selectedListItem?.type === 'chapter'
              ? selectedListItem?.nested?.map((item) => item?.name)
              : selectedListItem?.label;
          setName(getName);
          getUserAnalyticsData(selectedListItem?.id, selectedListItem?.type);
        }

        if (selectedListItem?.type === 'chapter') {
          setChapter(selectedListItem?.label);
        }
      }
      setActiveLevel(selectedListItem?.type);
      setActiveItem(selectedListItem.label);
      handleSelection(selectedListItem);
      setNewSelection(selectedListItem);
      handleSelectedData(selectedListItem);

      if (selectedListItem.layer === 2 && !selectedListItem.isExpanded) {
        setShowAnalytics(false);
      }
    }
  };

  const fetchAssessmentHierarchy = async () => {
    let token = localStorage.getItem('auth');
    await api
      .getHierarchyForAnalytics(token, courseId)
      .then((resp) => {
        setHeirarchyData(resp);
      })
      .catch((err) => console.error(err));
  };

  // Get User Analytics Data

  const getUserAnalyticsData = async (id, type) => {
    toggleLoading(true);
    setAnalyticsData(null);

    let token = localStorage.getItem('auth');
    await api
      .getUserAnalytics(token, id, type, updatedFilter, courseId)
      .then((res) => {
        setAnalyticsData(res);

        const attemptedData = res?.data?.map((item) => item?.correct);
        setAnalyticsCorrectAns(attemptedData);
        toggleLoading(false);
      })
      .catch((err) => console.error(err));
  };

  const getUserIncorrectData = async (question, snackId, resourceType) => {
    toggleLoading(true);
    const id = snackId ? snackId : newSelection?.id;
    const type = resourceType ? resourceType : newSelection?.type;
    setIsCorrect(question);
    let token = localStorage.getItem('auth');
    await api
      .getUserIncorrectQuestions(token, id, type, updatedFilter, question)
      .then((res) => {
        setIncorrectQuestions(res?.data);
        toggleLoading(false);
        setShowQuestions(true);
      })
      .catch((err) => console.error(err));
  };

  const deleteAnalytics = async () => {
    setLoading(true);

    let token = localStorage.getItem('auth');
    await api
      .deleteUserAnalytics(token, course?.id)
      .then((res) => {
        setLoading(false);
        setDeleteModal(false);
        setShowAnalytics(false);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    fetchAssessmentHierarchy();

    // if (newSelection?.id) {
    //   getUserAnalyticsData(newSelection?.id, newSelection?.type);
    // }
  }, []);

  useEffect(() => {
    if (selectedData?.layer === 1 && selectedData.isExpanded === false) {
      setShowAnalytics(false);
    }
  }, [selectedData]);

  useEffect(() => {
    if (newSelection?.id) {
      getUserAnalyticsData(newSelection?.id, newSelection?.type);
    }
  }, [updatedFilter]);

  return (
    <Box
      sx={{
        padding: { xs: '20px 10px', sm: '0px 0px' },
        marginBottom: { xs: '70px', sm: '0px' },
      }}
    >
      {deleteModal && (
        <>
          <DeleteModalWrapper>
            <DeleteModel>
              <DeletemodalClose onClick={() => setDeleteModal(false)}>
                <img src={GRAPHICS.Cross} alt="Cross" />
              </DeletemodalClose>

              <div>
                <img src={GRAPHICS.DeleteModalIcon} alt="Info" />
              </div>

              <ResetWrapper>
                <ConfirmationText>
                  Are you sure you want to reset "
                  {capitalize(course?.title || '')}" analytics?
                </ConfirmationText>

                <PurpleText>All your scores will be reset!</PurpleText>

                <PurpleText>This action cannot be undone.</PurpleText>
              </ResetWrapper>

              {loading === true ? (
                <Loader />
              ) : (
                <DeleteModalBtnWrapper>
                  <DeleteYesButton onClick={() => deleteAnalytics()}>
                    Yes
                  </DeleteYesButton>

                  <DeleteNoButton onClick={() => setDeleteModal(false)}>
                    No
                  </DeleteNoButton>
                </DeleteModalBtnWrapper>
              )}
            </DeleteModel>
          </DeleteModalWrapper>
        </>
      )}

      <GridComp container spacing={{ xs: 0, sm: 4 }}>
        <GridComp item xs={12}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: { xs: 'column', sm: 'row' },
              alignItems: 'center',
              width: '100%',
              marginBottom: '10px',
            }}
          >
            <BreadCrumb handleSelection={selectedData} />
            <Filter
              setFiler={setFiler}
              setUpdatedFilter={setUpdatedFilter}
              filter={filter}
            />
          </Box>
        </GridComp>

        <GridComp item xs={left} sx={{ display: { xs: 'none', sm: 'block' } }}>
          <CardComp nopadding={true}>
            <LeftSide
              onListClick={(e, i) => {
                listItemClickHandler(e, i);
              }}
              hierarchyData={hierarchyData}
              isLibrary={false}
              isFlashcard={false}
              isSimulation={false}
              isAssesment={false}
              selectedData={selectedData}
              setSelectedData={setSelectedData}
              setStructuredData={setStructuredData}
              tab={'analyticscards'}
            />
          </CardComp>
        </GridComp>

        <GridComp item xs={right}>
          {showAnalytics ? (
            <>
              <AnalyticsScreen
                names={names}
                chapter={chapter}
                activeLevel={activeLevel}
                attemptedAnswer={analyticsCorrectAns}
                analyticsData={analyticsData?.data}
                newSelection={newSelection}
                setDeleteModal={setDeleteModal}
                getUserIncorrectData={getUserIncorrectData}
                showQuestions={showQuestions}
                setShowQuestions={setShowQuestions}
                incorrectQuestions={incorrectQuestions}
                isCorrect={isCorrect}
              />
            </>
          ) : (
            <>
              <MobileCourseIndicator
                tab={'Analytics'}
                welcomeDetails="Please select a chapter or snack to view Analytics"
              />
              <AnalyticsHeaderWrapper>
                <AnalyticsNewUIWrapper _750px={_750px}>
                  <WelcomeImage src={GRAPHICS.Octopus} alt="img" />

                  <AnalyticsContent>
                    <WelcomeHeading>
                      Select a topic or snack on the left to view your
                      analytics.
                    </WelcomeHeading>

                    <UnorderList>
                      <li>
                        To see analytics on a topic, click on the topic title.
                      </li>

                      <li>
                        To see analytics on a snack (sub-topic), expand the
                        topic and click on the snack.
                      </li>
                    </UnorderList>
                  </AnalyticsContent>
                </AnalyticsNewUIWrapper>

                <ResetBtnWrapper>
                  <AnlayticsResetBtn onClick={() => setDeleteModal(true)}>
                    Reset Analytics
                  </AnlayticsResetBtn>
                </ResetBtnWrapper>
              </AnalyticsHeaderWrapper>
            </>
          )}
        </GridComp>
      </GridComp>

      <MobileBottomBar
        selectionText="Click here to get started "
        openMobileDropDown={openMobileBar}
        handleOpenMobileDropDown={handleOpenMobileBar}
        showData={!showAnalytics}
        selectedData={selectedData}
        leftSideMenu={
          <LeftSide
            onListClick={(e, i) => {
              listItemClickHandler(e, i);
            }}
            hierarchyData={hierarchyData}
            isLibrary={false}
            isFlashcard={false}
            isSimulation={false}
            handleOpenMobileDropDown={handleOpenMobileBar}
            active={activeItem}
            setStructuredData={setStructuredData}
            setSelectedData={setSelectedData}
            tab={'analyticscards'}
          />
        }
      />
    </Box>
  );
};

export default Analytics;
