import React from 'react';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import GRAPHICS from '../../../../../../../../assets/images/assessment';
import {
  QuestionText,
  RQuestionCard,
  CardNumber,
  CardContentWrapper,
  CardImgWrapper,
  CardImage,
  RadioBtnCustomLabel,
  StyledStatusIndicator,
} from '../Assessment.style';

const QuestionCard = ({
  item,
  index,
  totalQuestions,
  userAttemptedQuestins,
  setUserAttemptedQuestions,
  userId,
  resultCard,
  isCorrect,
  selectedOption,
  analytics,
  correctAnswer,
}) => {
  const handleCorrectAnswerChange = (event) => {
    const selectedOption = event.target.value;

    const isCorrect = item?.correctAnswer === selectedOption ? true : false;

    const question = {
      userId,
      questionId: item?.id,
      isCorrect,
      selectedOption,
    };

    const isPresent = userAttemptedQuestins?.find((item) => {
      return item?.questionId === question?.questionId;
    });

    if (isPresent) {
      setUserAttemptedQuestions((prev) => {
        const updatedAttemptedQuestions = prev?.map((item) => {
          if (item?.questionId === question?.questionId) {
            return question;
          }
          return item;
        });

        localStorage.setItem(
          'attemptedQuestions',
          JSON.stringify(updatedAttemptedQuestions)
        );
        return updatedAttemptedQuestions;
      });
    } else {
      setUserAttemptedQuestions((prev) => {
        const all = [...prev, question];
        localStorage.setItem('attemptedQuestions', JSON.stringify(all));
        return all;
      });
    }
  };

  return (
    <RQuestionCard>
      <CardNumber isResult={true}>
        {index + 1} / {totalQuestions}
      </CardNumber>

      <CardContentWrapper>
        {resultCard && (
          <div style={{ position: 'absolute', right: 0, top: 20 }}>
            <StyledStatusIndicator
              isCorrect={
                analytics === true
                  ? true
                  : analytics === false
                  ? false
                  : isCorrect
              }
            >
              {analytics === true
                ? 'Correct'
                : analytics === false
                ? 'Incorrect'
                : isCorrect === true
                ? 'Correct'
                : isCorrect === false
                ? 'Incorrect'
                : 'Skipped'}
            </StyledStatusIndicator>
          </div>
        )}

        <QuestionText>{item?.text}</QuestionText>

        <CardImgWrapper>
          <CardImage src={item?.publicKey} alt="img" />
        </CardImgWrapper>

        <div
          style={{
            marginTop: '10px',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <RadioGroup
            aria-label="correct-answer"
            name="correct-answer"
            value={
              correctAnswer
                ? correctAnswer
                : userAttemptedQuestins?.find(
                    (question) => question?.questionId === item?.id
                  )?.selectedOption || ''
            }
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '60%',
              justifyContent: 'space-around',
              fontWeight: 'bold',
              gap: '20px',
            }}
            onChange={handleCorrectAnswerChange}
          >
            {resultCard ? (
              <div style={{ position: 'relative' }}>
                {item?.correctAnswer === 'A' && isCorrect === true ? (
                  <img
                    style={{
                      width: 32,
                      height: 32,
                      position: 'absolute',
                      top: -19,
                      right: 70,
                    }}
                    src={GRAPHICS.Correct}
                    alt="Correct or Wrong"
                  ></img>
                ) : selectedOption === 'A' && isCorrect === false ? (
                  <img
                    style={{
                      position: 'absolute',
                      width: 32,
                      height: 32,
                      top: -19,
                      right: 70,
                    }}
                    src={GRAPHICS.Wrong}
                    alt="Correct or Wrong"
                  ></img>
                ) : (
                  ''
                )}

                <FormControlLabel
                  value="A"
                  control={
                    <Radio
                      sx={{
                        color: '#624BA2',
                        '&.Mui-checked': {
                          color: '#BF3895',
                        },
                      }}
                    />
                  }
                  label={<RadioBtnCustomLabel>A</RadioBtnCustomLabel>}
                  name="correctAnswer"
                  disabled={true}
                  style={{
                    cursor: 'not-allowed',
                    border:
                      item?.correctAnswer === 'A' ? '1px solid #81D0D4' : '',

                    background:
                      item?.correctAnswer === 'A'
                        ? '#EAF2F2'
                        : selectedOption === 'A' && isCorrect === false
                        ? '#FCEFF8'
                        : '',

                    borderRadius: '15px',
                    width: '150px',
                  }}
                />
              </div>
            ) : (
              <FormControlLabel
                value="A"
                control={
                  <Radio
                    sx={{
                      '&.Mui-checked': {
                        color: '#624BA2',
                      },
                    }}
                  />
                }
                label="A"
                name="correctAnswer"
              />
            )}

            {resultCard ? (
              <div style={{ position: 'relative' }}>
                {item?.correctAnswer === 'B' && isCorrect === true ? (
                  <img
                    style={{
                      height: 32,
                      position: 'absolute',
                      top: -19,
                      right: 70,
                    }}
                    src={GRAPHICS.Correct}
                    alt="Correct or Wrong"
                  ></img>
                ) : selectedOption === 'B' && isCorrect === false ? (
                  <img
                    style={{
                      height: 32,
                      position: 'absolute',
                      top: -19,
                      right: 70,
                    }}
                    src={GRAPHICS.Wrong}
                    alt="Correct or Wrong"
                  ></img>
                ) : (
                  ''
                )}

                <FormControlLabel
                  value="B"
                  control={
                    <Radio
                      sx={{
                        color: '#624BA2',
                        '&.Mui-checked': {
                          color: '#BF3895',
                        },
                      }}
                    />
                  }
                  label={<RadioBtnCustomLabel>B</RadioBtnCustomLabel>}
                  name="correctAnswer"
                  disabled={true}
                  style={{
                    cursor: 'not-allowed',
                    border:
                      item?.correctAnswer === 'B' ? '1px solid #81D0D4' : '',

                    background:
                      item?.correctAnswer === 'B'
                        ? '#EAF2F2'
                        : selectedOption === 'B' && isCorrect === false
                        ? '#FCEFF8'
                        : '',
                    // border: `1px solid ${
                    //   isCorrect === true
                    //     ? '#ECF8F9'
                    //     : isCorrect === false
                    //     ? '#FCEFF8'
                    //     : '#81D0D4'
                    // }`,
                    borderRadius: '15px',
                    width: '150px',
                  }}
                />
              </div>
            ) : (
              <FormControlLabel
                value="B"
                control={
                  <Radio
                    sx={{
                      '&.Mui-checked': {
                        color: '#624BA2',
                      },
                    }}
                  />
                }
                label="B"
                name="correctAnswer"
              />
            )}

            {resultCard ? (
              <div style={{ position: 'relative' }}>
                {item?.correctAnswer === 'C' && isCorrect === true ? (
                  <img
                    style={{
                      height: 32,
                      position: 'absolute',
                      top: -19,
                      right: 70,
                    }}
                    src={GRAPHICS.Correct}
                    alt="Correct or Wrong"
                  ></img>
                ) : selectedOption === 'C' && isCorrect === false ? (
                  <img
                    style={{
                      height: 32,
                      position: 'absolute',
                      top: -19,
                      right: 70,
                    }}
                    src={GRAPHICS.Wrong}
                    alt="Correct or Wrong"
                  ></img>
                ) : (
                  ''
                )}

                <FormControlLabel
                  value="C"
                  control={
                    <Radio
                      sx={{
                        color: '#624BA2',
                        '&.Mui-checked': {
                          color: '#BF3895',
                        },
                      }}
                    />
                  }
                  label={<RadioBtnCustomLabel>C</RadioBtnCustomLabel>}
                  name="correctAnswer"
                  disabled={true}
                  style={{
                    cursor: 'not-allowed',
                    border:
                      item?.correctAnswer === 'C' ? '1px solid #81D0D4' : '',

                    background:
                      item?.correctAnswer === 'C'
                        ? '#EAF2F2'
                        : selectedOption === 'C' && isCorrect === false
                        ? '#FCEFF8'
                        : '',
                    // border: `1px solid ${
                    //   isCorrect === true
                    //     ? '#ECF8F9'
                    //     : isCorrect === false
                    //     ? '#FCEFF8'
                    //     : '#81D0D4'
                    // }`,
                    borderRadius: '15px',
                    width: '150px',
                  }}
                />
              </div>
            ) : (
              <FormControlLabel
                value="C"
                control={
                  <Radio
                    sx={{
                      '&.Mui-checked': {
                        color: '#624BA2',
                      },
                    }}
                  />
                }
                label="C"
                name="correctAnswer"
              />
            )}

            {resultCard ? (
              <div style={{ position: 'relative' }}>
                {item?.correctAnswer === 'D' && isCorrect === true ? (
                  <img
                    style={{
                      height: 32,
                      position: 'absolute',
                      top: -19,
                      right: 70,
                    }}
                    src={GRAPHICS.Correct}
                    alt="Correct or Wrong"
                  ></img>
                ) : selectedOption === 'D' && isCorrect === false ? (
                  <img
                    style={{
                      height: 32,
                      position: 'absolute',
                      top: -19,
                      right: 70,
                    }}
                    src={GRAPHICS.Wrong}
                    alt="Correct or Wrong"
                  ></img>
                ) : (
                  ''
                )}

                <FormControlLabel
                  value="D"
                  control={
                    <Radio
                      sx={{
                        color: '#624BA2',
                        '&.Mui-checked': {
                          color: '#BF3895',
                        },
                      }}
                    />
                  }
                  label={<RadioBtnCustomLabel>D</RadioBtnCustomLabel>}
                  name="correctAnswer"
                  disabled={true}
                  style={{
                    cursor: 'not-allowed',
                    border:
                      item?.correctAnswer === 'D' ? '1px solid #81D0D4' : '',
                    background:
                      item?.correctAnswer === 'D'
                        ? '#EAF2F2'
                        : selectedOption === 'D' && isCorrect === false
                        ? '#FCEFF8'
                        : '',
                    // border: `1px solid ${
                    //   isCorrect === true
                    //     ? '#ECF8F9'
                    //     : isCorrect === false
                    //     ? '#FCEFF8'
                    //     : '#81D0D4'
                    // }`,
                    borderRadius: '15px',
                    width: '150px',
                  }}
                />
              </div>
            ) : (
              <FormControlLabel
                value="D"
                control={
                  <Radio
                    sx={{
                      '&.Mui-checked': {
                        color: '#624BA2',
                      },
                    }}
                  />
                }
                label="D"
                name="correctAnswer"
              />
            )}
          </RadioGroup>
        </div>
      </CardContentWrapper>
    </RQuestionCard>
  );
};

export default QuestionCard;
