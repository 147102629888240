import * as React from 'react';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { AntTab, Wrapper, BoxWrapper } from './Secondary.style';
import PropTypes from 'prop-types';
import { useParams, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import IframeComp from '../../../Iframe/iframe';
import { Mixpanel } from '../../../../helpers/Mixpanel';
import pallete from '../../../../config/palette';

import { formatText } from '../../List/Utils';
import { useDispatch, useSelector } from 'react-redux';
import { learningResourcesHighlightActions } from '../../../../redux/reducers/learningResourcesHighlight';

const Secondary = ({ tabs, tabIndicatorColor, selectedtabcolor, location }) => {
  const [tabValue, setValue] = React.useState(0);
  const { name, tab, id, value } = useParams();
  const isHighlightingActive = useSelector(
    (state) => state.learningResources.isHighlighting
  );
  const user = useSelector((state) => state.user);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleChange = (event, newValue) => {
    setValue(newValue);

    navigate(`/dashboard/${name}/${id}/${value}/${location}/${newValue}`);
  };
  useEffect(() => {
    Mixpanel.identify(user.id);
    Mixpanel.track(`Course Selected `, {
      name: name,
      id: id,
      description: `Selected Course : ${formatText(name)}`,
      page: 'Dashboard',
    });
  }, []);

  useEffect(() => {
    Mixpanel.track(
      `Selected ${formatText(
        location === 'octilearnlibrary' ? 'OctiLearn Library' : 'My Library'
      )} in ${formatText(name)}`,
      {
        name: name,
        id: id,
        page: 'Dashboard',
      }
    );
  }, [location]);

  useEffect(() => {
    if (tab) {
      setValue(parseInt(tab));
    }

    let selectedTab;

    if (location === 'octilearnlibrary') {
      if (parseInt(tab) === 0) {
        selectedTab = 'Syllabus';

        Mixpanel.track(`Selected OctiLearn Library Syllabus`, {
          courseName: name,
          id: id,
          selectedTab: tab,
          page: 'Syllabus',
          description: `Selected OctiLearn Library ${formatText(
            selectedTab
          )} in ${formatText(name)}`,
        });
      } else if (parseInt(tab) === 1) {
        selectedTab = 'Notes';

        Mixpanel.track(`Selected OctiLearn Library Notes`, {
          courseName: name,
          id: id,
          selectedTab: tab,
          page: 'Notes',
          description: `Selected OctiLearn Library ${formatText(
            selectedTab
          )} in ${formatText(name)}`,
        });
      } else if (parseInt(tab) === 2) {
        selectedTab = 'FlashCards';

        Mixpanel.track(`Selected OctiLearn Library Flashcards`, {
          courseName: name,
          id: id,
          selectedTab: tab,
          page: 'FlashCards',
          description: `Selected OctiLearn Library ${formatText(
            selectedTab
          )} in ${formatText(name)}`,
        });
      } else if (parseInt(tab) === 3) {
        selectedTab = 'Assessment';

        Mixpanel.track(`Selected OctiLearn Library Assessments`, {
          courseName: name,
          id: id,
          selectedTab: tab,
          page: 'Assessment',
          description: `Selected OctiLearn Library ${formatText(
            selectedTab
          )} in ${formatText(name)}`,
        });
      } else if (parseInt(tab) === 4) {
        selectedTab = 'Analytics';

        Mixpanel.track(`Selected OctiLearn Library Analytics`, {
          courseName: name,
          id: id,
          selectedTab: tab,
          page: 'Analytics',
          description: `Selected OctiLearn Library ${formatText(
            selectedTab
          )} in ${formatText(name)}`,
        });
      } else {
        selectedTab = 'Simulations';

        Mixpanel.track(`Selected OctiLearn Library Simulations`, {
          courseName: name,
          id: id,
          selectedTab: tab,
          page: 'Simulations',
          description: `Selected OctiLearn Library ${formatText(
            selectedTab
          )} in ${formatText(name)}`,
        });
      }
    } else {
      if (parseInt(tab) === 0) {
        selectedTab = 'Notes';

        Mixpanel.track(`Selected My Library Notes`, {
          courseName: name,
          id: id,
          selectedTab: tab,
          page: 'My Library Notes',
          description: `Selected My Library ${formatText(
            selectedTab
          )} in ${formatText(name)}`,
        });
      } else {
        selectedTab = 'FlashCards';

        Mixpanel.track(`Selected My Library Flashcards`, {
          courseName: name,
          id: id,
          selectedTab: tab,
          page: 'My Library FlashCards',
          description: `Selected My Library ${formatText(
            selectedTab
          )} in ${formatText(name)}`,
        });
      }
    }

    if (isHighlightingActive) {
      dispatch(learningResourcesHighlightActions.toggleHighlightState());
    }
  }, [tab]);

  const [showBot, setShowBot] = React.useState(false);

  const HandleBotClick = () => {
    setShowBot(!showBot);

    Mixpanel.track('Chatbot (Widget) Clicked', {
      page: 'Physics course',
    });
  };

  return (
    <>
      <Wrapper>
        <TabContext value={tabValue}>
          <BoxWrapper sx={{ display: 'block', marginBottom: '-10px' }}>
            <TabList
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              left
              onChange={handleChange}
              aria-label="lab API tabs example"
              TabIndicatorProps={{ style: { background: tabIndicatorColor } }}
            >
              {tabs.map((tab, index) => (
                <AntTab
                  icon={
                    <tab.icon
                      isMobile={true}
                      color={
                        tabValue === tab.value
                          ? pallete.colors.secondaryModified
                          : '#11263CB2'
                      }
                    />
                  }
                  key={index}
                  label={tab.label}
                  value={tab.value}
                  selectedtabcolor={pallete.colors.secondaryModified}
                />
              ))}
            </TabList>
          </BoxWrapper>
          {tabs.map((tab, index) => (
            <TabPanel value={tab.value} key={index}>
              {tab.content}
            </TabPanel>
          ))}
        </TabContext>
      </Wrapper>

      <IframeComp
        showBot={showBot}
        HandleBotClick={HandleBotClick}
        url={'https://www.chatbase.co/chatbot-iframe/-_xqd7Unl_jkApvtNgaLJ'}
      />
    </>
  );
};

export default Secondary;
Secondary.propTypes = {
  tabIndicatorColor: PropTypes.string,
  selectedtabcolor: PropTypes.string,
};
Secondary.defaultProps = {
  tabIndicatorColor: '#fff',
  selectedtabcolor: '#624BA2',
};
