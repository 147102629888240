/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import List from '../../../UI/List/List';
import api from '../../../../Services';
import IconBar from './../../../UI/List/IconBar';
import Loader from './../../../UI/Loader/Loader';
import ErrorMessage from '../../../UI/ErrorMessage/ErrorMessage';
import { useParams } from 'react-router-dom';
import ErrorBoundary from './../../../../Errors/ErrorBoundary';
import { useSelector, useDispatch } from 'react-redux';
import { learningResourcesNotesListControllerActions } from '../../../../redux/reducers/learningResourcesNotesListController';
import { courseActions } from '../../../../redux/reducers/course';

const LeftSide = ({
  onListClick,
  selectedFromList,
  getMenuStats,
  hierarchyData,
  isLibrary,
  isFlashcard,
  active,
  handleOpenMobileDropDown,
  isNotes,
  isSimulation,
  isAssesment,
  selectedData,
  activeScreen,
  setActiveScreen,
  setSelectedData,
  setStructuredData,
  setinitialData,
  tab,
  completedFC,
  updatedData,
}) => {
  const dispatch = useDispatch();

  const open = useSelector(
    (state) => state.learningResourcesNotesListController.value
  );

  const [structure, setStructure] = React.useState(null);
  const [isListDataLoading, setIsListDataLoading] = React.useState(false);
  const [listDataErr, setListDataErr] = React.useState(false);
  const [updatedStructure, setUpdatedStructure] = React.useState(null);

  const { id: courseId } = useParams();

  const toggleOpen = (val) =>
    dispatch(learningResourcesNotesListControllerActions.setValue(val));

  const getMenufromDB = async (token) => {
    let temp = await api.getSyllabus(token, courseId);
    return temp;
  };

  useEffect(() => {
    if (setStructuredData) {
      setStructuredData(structure);

      if (tab === 'notes' || tab === 'assessment' || tab === 'flashcards') {
        setinitialData(structure);
      }
    }
  }, [structure]);

  useEffect(() => {
    makeList(hierarchyData);
  }, [hierarchyData, completedFC, updatedData]);

  const makeList = (el) => {
    if (el?.statusCode === 200) {
      if (isLibrary) {
        if (el.isUnitsAVailable) {
          let units = el.data;
          units.sort((a, b) => {
            return a.id - b.id;
          });
          const unitsData = units.map((unit) => {
            const unitIds = unit.unitId;
            const unitName = unit.unitName;
            const filteredChapter = unit.chapters.filter(
              (fil) => fil.unitId === unitIds
            );

            filteredChapter.sort((a, b) => {
              return a.id - b.id;
            });

            return {
              type: 'unit',
              layer: 1,
              name: unitName,
              label: unitName,
              id: unitIds,
              isExpanded: false,

              icon: <IconBar />,
              childrenElements:
                filteredChapter.length === 0
                  ? null
                  : [
                      ...filteredChapter.map((chItem) => {
                        const chapterIds = chItem.chapterId;
                        const chapterName = chItem.chapterName;
                        const snakData = [...chItem.snacks];

                        snakData.sort((a, b) => {
                          return a.id - b.id;
                        });
                        const filteredSnack = snakData.filter(
                          (item) => item.chapterId === chapterIds
                        );
                        if (chItem.snacks) {
                          return {
                            type: 'chapter',
                            layer: 2,
                            name: chapterName,
                            unitId: unitIds,
                            id: chapterIds,
                            label: chapterName,
                            isExpanded: false,

                            icon: <IconBar />,
                            childrenElements:
                              filteredSnack.length === 0
                                ? null
                                : [
                                    ...filteredSnack.map(
                                      (snackItem, snackIndex) => {
                                        const snackName = snackItem.snackName;
                                        const snackId = snackItem.snackId;
                                        const tags = snackItem.tags;
                                        return {
                                          type: 'snack',
                                          layer: 3,
                                          name: snackName,
                                          unitId: unitIds,
                                          chapterId: chapterIds,
                                          id: snackId,
                                          isExpanded: false,
                                          icon: <IconBar />,
                                          label: snackName,
                                          tags,
                                        };
                                      }
                                    ),
                                  ],
                          };
                        } else {
                          return {
                            type: 'chapter',
                            layer: 2,
                            name: chapterName,
                            unitId: unitIds,
                            id: chapterIds,
                            label: chapterName,
                            isExpanded: false,
                          };
                        }
                      }),
                    ],
            };
          });
          setStructure(unitsData);
          setIsListDataLoading(false);
        } else {
          el.data.sort((a, b) => {
            return a.id - b.id;
          });
          const chaptersData = el.data.map((chItem, index) => {
            const chapterIds = chItem.chapterId;
            const chapterName = chItem.chapterName;
            const _snakData = [...chItem.snacks];

            _snakData.sort((a, b) => {
              return a.id - b.id;
            });
            const filteredSnackwithoutUnit = _snakData.filter(
              (item) => item.chapterId === chapterIds
            );
            if (chItem.snacks) {
              return {
                type: 'chapter',
                layer: 1,
                name: chapterName,
                id: chapterIds,
                icon: <IconBar />,
                label: chapterName,
                isExpanded: false,
                childrenElements:
                  filteredSnackwithoutUnit.length === 0
                    ? null
                    : [
                        ...filteredSnackwithoutUnit.map((snackItem, _) => {
                          const snackName = snackItem.snackName;
                          const snackId = snackItem.snackId;
                          const tags = snackItem.tags;
                          return {
                            type: 'snack',
                            layer: 2,
                            id: snackId,
                            name: snackName,
                            icon: <IconBar />,
                            label: snackName,
                            isExpanded: false,
                            tags,
                          };
                        }),
                      ],
              };
            } else {
              return {
                type: 'chapter',
                layer: 1,
                name: chapterName,
                id: chapterIds,
                label: chapterName,
              };
            }
          });
          setStructure(chaptersData);
          setIsListDataLoading(false);
        }
      } else {
        if (el.isUnitsAvailable) {
          let units = el.data;
          units.sort((a, b) => {
            return a.id - b.id;
          });

          const unitsData = units.map((unit) => {
            const unitIds = unit?.unitId ? unit?.unitId : unit?.id;
            const unitName = unit?.unitName ? unit?.unitName : unit?.title;
            const filteredChapter = unit.chapters.filter(
              (fil) => fil.unitId === unitIds
            );

            filteredChapter.sort((a, b) => {
              return a.id - b.id;
            });

            return {
              type: 'unit',
              layer: 1,
              completionPercentage: unit?.completionPercentage,
              name: unitName,
              label: unitName,
              isExpanded: false,
              id: unitIds,
              icon: <IconBar />,
              childrenElements:
                filteredChapter.length === 0
                  ? null
                  : [
                      ...filteredChapter.map((chItem) => {
                        const chapterIds = chItem?.chapterId
                          ? chItem?.chapterId
                          : chItem?.id;
                        const chapterName = chItem?.chapterName
                          ? chItem?.chapterName
                          : chItem?.title;

                        const completionPercentage =
                          chItem?.completionPercentage
                            ? chItem?.completionPercentage
                            : null;

                        if (isSimulation === false) {
                          const snakData = [...chItem?.snacks];

                          snakData?.sort((a, b) => {
                            return a.id - b.id;
                          });
                          const filteredSnack = snakData?.filter(
                            (item) => item.chapterId === chapterIds
                          );

                          if (chItem?.snacks) {
                            return {
                              type: 'chapter',
                              layer: 2,
                              name: chapterName,
                              completionPercentage,
                              id: chapterIds,
                              unitId: unitIds,
                              label: chapterName,
                              icon: <IconBar />,

                              isExpanded:
                                selectedData?.type === 'chapter'
                                  ? selectedData?.id === chapterIds
                                  : selectedData?.chapterId === chapterIds
                                  ? selectedData?.isExpanded
                                  : false,

                              childrenElements:
                                filteredSnack?.length === 0
                                  ? null
                                  : [
                                      ...filteredSnack?.map((snackItem, _) => {
                                        const snackName = snackItem?.snackName
                                          ? snackItem?.snackName
                                          : snackItem?.title;
                                        const snackId = snackItem?.snackId
                                          ? snackItem?.snackId
                                          : snackItem?.id;

                                        return {
                                          type: 'snack',
                                          layer: 3,
                                          name: snackName,
                                          completionPercentage: isFlashcard
                                            ? snackItem?.completionPercentage
                                            : snackItem?.completed,
                                          id: snackId,
                                          unitId: unitIds,
                                          chapterId: chapterIds,
                                          icon: <IconBar />,
                                          label: snackName,
                                          isExpanded:
                                            selectedData &&
                                            selectedData?.id === snackId
                                              ? selectedData?.isExpanded
                                              : false,
                                        };
                                      }),
                                    ],
                            };
                          }
                        } else {
                          return {
                            type: 'chapter',
                            layer: 2,
                            name: chapterName,
                            id: chapterIds,
                            unitId: unitIds,
                            label: chapterName,
                            isExpanded: false,
                          };
                        }
                      }),
                    ],
            };
          });

          if (isNotes) {
            const savedNotesData =
              JSON.parse(localStorage.getItem('notesGlobalState')) || {};

            const updatedData = savedNotesData?.data?.filter((item) => {
              return item?.courseId === courseId;
            });

            const data = updatedData?.length > 0 ? updatedData : structure;

            const mergedData = mergeCompletionPercentage(data, unitsData);

            setUpdatedStructure(mergedData);
          }

          if (isAssesment) {
            const savedNotesData =
              JSON.parse(localStorage.getItem('assessmentGlobalState')) || {};

            const updatedData = savedNotesData?.data?.filter((item) => {
              return item?.courseId === courseId;
            });

            const data = updatedData?.length > 0 ? updatedData : structure;

            const mergedData = mergeCompletionPercentage(data, unitsData);

            setUpdatedStructure(mergedData);
          }

          if (isFlashcard) {
            const savedFlashcardsData =
              JSON.parse(localStorage.getItem('flashcardsGlobalState')) || {};

            const updatedData = savedFlashcardsData?.data?.filter((item) => {
              return item?.courseId === courseId;
            });

            const data = updatedData?.length > 0 ? updatedData : structure;

            const mergedData = mergeCompletionPercentage(data, unitsData);

            setUpdatedStructure(mergedData);
          }

          setStructure(unitsData);

          setIsListDataLoading(false);
        } else {
          if (isNotes) {
            el.data.sort((a, b) => {
              return a.id - b.id;
            });

            const chaptersData = el.data.map((chItem, index) => {
              const chapterIds = chItem.chapterId;
              const chapterName = chItem.chapterName;
              const _snakData = [...chItem.snacks];

              _snakData.sort((a, b) => {
                return a.id - b.id;
              });
              const filteredSnackwithoutUnit = _snakData.filter(
                (item) => item.chapterId === chapterIds
              );

              if (chItem.snacks) {
                return {
                  type: 'chapter',
                  layer: 1,
                  name: chapterName,
                  id: chapterIds,
                  completionPercentage: chItem?.completionPercentage,
                  icon: <IconBar />,
                  label: chapterName,
                  isExpanded: false,
                  childrenElements:
                    filteredSnackwithoutUnit.length === 0
                      ? null
                      : [
                          ...filteredSnackwithoutUnit.map((snackItem, _) => {
                            const snackName = snackItem.snackName;
                            const snackId = snackItem.snackId;
                            const tags = snackItem.tags;
                            return {
                              type: 'snack',
                              layer: 2,
                              id: snackId,
                              name: snackName,
                              completionPercentage: snackItem?.completed,
                              icon: <IconBar />,
                              label: snackName,
                              tags,
                              isExpanded: false,
                              chapterId: chapterIds,
                            };
                          }),
                        ],
                };
              } else {
                return {
                  type: 'chapter',
                  layer: 1,
                  name: chapterName,
                  id: chapterIds,
                  label: chapterName,
                };
              }
            });

            if (isNotes) {
              const savedNotesData =
                JSON.parse(localStorage.getItem('notesGlobalState')) || {};

              const updatedData = savedNotesData?.data?.filter((item) => {
                return item?.courseId === courseId;
              });

              const data = updatedData?.length > 0 ? updatedData : structure;
              const mergedData = mergeCompletionPercentage(data, chaptersData);

              setUpdatedStructure(mergedData);
            }

            setStructure(chaptersData);
            setIsListDataLoading(false);
          } else if (isFlashcard) {
            el.data.chapters.sort((a, b) => {
              return a.id - b.id;
            });
            const chaptersData = el.data.chapters.map((chItem, _) => {
              const chapterIds = chItem.id;
              const chapterName = chItem.title;

              if (isSimulation === false) {
                const _snakData = [...chItem.snacks];

                _snakData.sort((a, b) => {
                  return a.id - b.id;
                });
                const filteredSnackwithoutUnit = _snakData.filter(
                  (item) => item.chapterId === chapterIds
                );

                const completionPercentage = chItem?.completionPercentage
                  ? chItem?.completionPercentage
                  : null;

                if (chItem.snacks) {
                  return {
                    type: 'chapter',
                    layer: 1,
                    name: chapterName,
                    id: chapterIds,
                    completionPercentage,
                    icon: <IconBar />,
                    label: chapterName,
                    isExpanded: false,
                    childrenElements:
                      filteredSnackwithoutUnit.length === 0
                        ? null
                        : [
                            ...filteredSnackwithoutUnit.map((snackItem, _) => {
                              const snackName = snackItem.title;
                              const snackId = snackItem.id;
                              return {
                                type: 'snack',
                                layer: 2,
                                id: snackId,
                                name: snackName,
                                completionPercentage: isFlashcard
                                  ? snackItem?.completionPercentage
                                  : snackItem?.completed,
                                icon: <IconBar />,
                                label: snackName,
                                isExpanded: false,
                              };
                            }),
                          ],
                  };
                }
              } else {
                return {
                  type: 'chapter',
                  layer: 1,
                  name: chapterName,
                  id: chapterIds,
                  label: chapterName,
                  isExpanded: false,
                };
              }
            });

            if (isFlashcard) {
              const savedFlashcardsData =
                JSON.parse(localStorage.getItem('flashcardsGlobalState')) || {};

              const updatedData = savedFlashcardsData?.data?.filter((item) => {
                return item?.courseId === courseId;
              });

              const data = updatedData?.length > 0 ? updatedData : structure;
              const mergedData = mergeCompletionPercentage(data, chaptersData);

              setUpdatedStructure(mergedData);
            }

            setStructure(chaptersData);
            setIsListDataLoading(false);
          } else {
            el.data[0].chapters.sort((a, b) => {
              return a.id - b.id;
            });
            const chaptersData = el.data[0].chapters.map((chItem, _) => {
              const chapterIds = chItem.id;
              const chapterName = chItem.title;

              if (isSimulation === false) {
                const _snakData = [...chItem.snacks];

                _snakData.sort((a, b) => {
                  return a.id - b.id;
                });
                const filteredSnackwithoutUnit = _snakData.filter(
                  (item) => item.chapterId === chapterIds
                );
                if (chItem.snacks) {
                  return {
                    type: 'chapter',
                    layer: 1,
                    name: chapterName,
                    id: chapterIds,
                    icon: <IconBar />,
                    label: chapterName,
                    isExpanded: false,
                    childrenElements:
                      filteredSnackwithoutUnit.length === 0
                        ? null
                        : [
                            ...filteredSnackwithoutUnit.map((snackItem, _) => {
                              const snackName = snackItem.title;
                              const snackId = snackItem.id;

                              return {
                                type: 'snack',
                                layer: 2,
                                id: snackId,
                                name: snackName,
                                icon: <IconBar />,
                                label: snackName,
                                isExpanded: false,
                              };
                            }),
                          ],
                  };
                }
              } else {
                return {
                  type: 'chapter',
                  layer: 1,
                  name: chapterName,
                  id: chapterIds,
                  label: chapterName,
                };
              }
            });

            if (isAssesment) {
              const savedAssessmentData =
                JSON.parse(localStorage.getItem('assessmentGlobalState')) || {};

              const updatedData = savedAssessmentData?.data?.filter((item) => {
                return item?.courseId === courseId;
              });

              const data = updatedData?.length > 0 ? updatedData : structure;
              const mergedData = mergeCompletionPercentage(data, chaptersData);

              setUpdatedStructure(mergedData);
            }

            setStructure(chaptersData);
            setIsListDataLoading(false);
          }
        }
      }
    }
  };
  const getListData = async () => {
    let token = localStorage.getItem('auth');
    getMenufromDB(token)
      .then((el) => {
        if (el.error) {
          return;
        }
        makeList({ data: el });
        dispatch(courseActions.addCourse(el));
      })
      .catch((error) => console.error(error));
  };

  React.useEffect(() => {
    setIsListDataLoading(true);
  }, []);

  function mergeCompletionPercentage(savedData, unitsData = []) {
    const unitsMap = new Map(unitsData.map((unit) => [unit.id, unit]));

    if (savedData) {
      savedData.forEach((savedUnit) => {
        const unit = unitsMap.get(savedUnit.id);
        if (unit) {
          savedUnit.completionPercentage = unit.completionPercentage;
        }

        savedUnit.childrenElements?.forEach((savedChapter) => {
          let chapter;
          if (unit) {
            chapter = unit.childrenElements.find(
              (ch) => ch.id === savedChapter.id
            );
          }

          if (chapter) {
            savedChapter.completionPercentage = chapter.completionPercentage;
          }

          savedChapter.childrenElements?.forEach((savedSnack) => {
            let snack;
            if (chapter) {
              snack = chapter.childrenElements.find(
                (sn) => sn.id === savedSnack.id
              );
            }

            if (snack) {
              savedSnack.completionPercentage = snack.completionPercentage;
            }
          });
        });
      });
    }

    return savedData;
  }

  React.useEffect(() => {
    if (hierarchyData) {
      if (tab === 'notes') {
        const savedNotesData =
          JSON.parse(localStorage.getItem('notesGlobalState')) || {};

        if (savedNotesData?.data?.length > 0) {
          const updatedData = savedNotesData?.data?.filter((item) => {
            return item?.courseId === courseId;
          });

          if (updatedData?.legnth > 0) {
            setStructure(updatedData);
          } else {
            makeList(hierarchyData);
          }
          setIsListDataLoading(false);

          if (updatedStructure === null) {
            onListClick(savedNotesData?.selection, true);
          }
        } else {
          makeList(hierarchyData);
        }
      } else if (tab === 'flashcards') {
        const savedFlashcardsData =
          JSON.parse(localStorage.getItem('flashcardsGlobalState')) || {};

        if (savedFlashcardsData?.data?.length > 0) {
          const updatedData = savedFlashcardsData?.data?.filter((item) => {
            return item?.courseId === courseId;
          });

          if (updatedData?.legnth > 0) {
            setStructure(updatedData);
          } else {
            makeList(hierarchyData);
          }
          setIsListDataLoading(false);

          if (updatedStructure === null) {
            onListClick(savedFlashcardsData?.selection, true);
          }
        } else {
          makeList(hierarchyData);
        }
      } else if (tab === 'assessment') {
        const savedAssessmentData =
          JSON.parse(localStorage.getItem('assessmentGlobalState')) || {};

        if (savedAssessmentData?.data?.length > 0) {
          const updatedData = savedAssessmentData?.data?.filter((item) => {
            return item?.courseId === courseId;
          });

          if (updatedData?.legnth > 0) {
            setStructure(updatedData);
          } else {
            makeList(hierarchyData);
          }
          setIsListDataLoading(false);

          if (updatedStructure === null) {
            onListClick(savedAssessmentData?.selection, true);
          }
        } else {
          makeList(hierarchyData);
        }
      } else if (tab === 'analyticscards') {
        const savedAnalyticscardsData =
          JSON.parse(localStorage.getItem('analyticscardsGlobalState')) || {};
        if (savedAnalyticscardsData?.data?.length > 0) {
          setStructure(savedAnalyticscardsData?.data);
          setIsListDataLoading(false);
          onListClick(savedAnalyticscardsData?.selection, true);
        } else {
          makeList(hierarchyData);
        }
      } else if (tab === 'simulationscards') {
        const savedSimultionsData =
          JSON.parse(localStorage.getItem('simulationscardsGlobalState')) || {};
        if (savedSimultionsData?.data?.length > 0) {
          setStructure(savedSimultionsData?.data);
          setIsListDataLoading(false);
          onListClick(savedSimultionsData?.selection, true);
        } else {
          makeList(hierarchyData);
        }
      } else {
        makeList(hierarchyData);
      }
    }
  }, [hierarchyData, tab]);

  React.useEffect(() => {
    if (getMenuStats === true) {
      setIsListDataLoading(true);
      getListData();
    }
  }, [getMenuStats]);

  useEffect(() => {
    if (isNotes) {
      const savedNotesData =
        JSON.parse(localStorage.getItem('notesGlobalState')) || {};

      if (
        updatedStructure !== null &&
        isNotes &&
        savedNotesData?.data?.length > 0
      ) {
        setStructure(updatedStructure);
      }
    }

    if (isAssesment) {
      const savedAsessmentData =
        JSON.parse(localStorage.getItem('assessmentGlobalState')) || {};

      if (
        updatedStructure !== null &&
        isAssesment &&
        savedAsessmentData?.data?.length > 0
      ) {
        setStructure(updatedStructure);
      }
    }

    if (isFlashcard) {
      const savedFlashcardsData =
        JSON.parse(localStorage.getItem('flashcardsGlobalState')) || {};

      if (
        updatedStructure !== null &&
        isFlashcard &&
        savedFlashcardsData?.data?.length > 0
      ) {
        setStructure(updatedStructure);
      }
    }
  }, [updatedStructure]);

  return (
    <>
      <ErrorBoundary>
        {isListDataLoading ? (
          <Loader />
        ) : (
          <>
            {listDataErr ? (
              <ErrorMessage style={{ padding: '2rem 0.4rem' }}>
                Error, unable to retrieve data
              </ErrorMessage>
            ) : (
              <>
                {structure && (
                  <List
                    setStructure={setStructure}
                    structure={structure}
                    open={open}
                    setOpen={toggleOpen}
                    selectedData={selectedData}
                    selection={selectedFromList}
                    listSkeleton={structure}
                    onItemClick={(e, i) => onListClick(e, i)}
                    isFlashcard={isFlashcard}
                    isLibrary={isLibrary}
                    active={active}
                    handleOpenMobileDropDown={handleOpenMobileDropDown}
                    isAssesment={isAssesment}
                    activeScreen={activeScreen}
                    setActiveScreen={setActiveScreen}
                    setSelectedData={setSelectedData}
                    isNotes={isNotes}
                  />
                )}
              </>
            )}
          </>
        )}
      </ErrorBoundary>
    </>
  );
};

export default LeftSide;

LeftSide.defaultProps = {
  getList: (e) => {
    return;
  },
  getCourseInfo: (e) => {
    return;
  },
};
